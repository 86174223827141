<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" key="login">
      <!-- Brand logo-->
      <b-link class="brand-logo" href="/">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ $appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <transition appear name="fade" mode="out-in">
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Welcome to {{ $appName }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Sign-in to your account and start the adventure
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <!-- email -->
                <b-form-group label="Mobile number" label-for="login-mobile">
                  <validation-provider
                    #default="{ errors }"
                    name="Mobile number"
                    rules="required"
                  >
                    <b-form-input
                      id="login-mobile"
                      v-model="userMobile"
                      :state="errors.length > 0 ? false : null"
                      name="login-mobile"
                      placeholder="639091234567"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{ name: 'forgot-pw' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-overlay :show="show" rounded="sm" spinner-variant="primary" spinner-small class="d-inline-block">
                  <b-button type="submit" variant="primary" block @click="validationForm">Sign in</b-button>
                </b-overlay>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{ name: 'page-auth-register' }">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <!-- <div class="divider my-2">
              <div class="divider-text">or</div>
            </div> -->

            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
              <b-button variant="facebook" href="javascript:void(0)">
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button variant="twitter" href="javascript:void(0)">
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button variant="google" href="javascript:void(0)">
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button variant="github" href="javascript:void(0)">
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
          </b-col>
        </b-col>
      </transition>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    Ripple
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userMobile: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      show: false,
      required,
      // mobile,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.show = true
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          // Determine whether the requesting app/host is for admin or user.
          // window.location.hostname.includes('admin') ? useJwt.setAuthAction('admin') : useJwt.setAuthAction('user')
          useJwt.setAuthAction('admin')
          // Then proceed with login.
          useJwt
            .login({
              mobile: this.userMobile,
              password: this.password,
            })
            .then((response) => {
              const userData = response.data;
              if (userData.result) {
                // useJwt.setAction('reseller')
                this.show = false
                useJwt.setToken(JSON.stringify(response.data));
                this.$router
                  .replace(userData.redirectTo)
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Welcome ${
                          userData.fullName || userData.username
                        }`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `You have successfully logged in. Now you can start to explore!`,
                      },
                    });
                  })
                  .catch((error) => {
                    this.show = false
                    this.$refs.loginForm.setErrors(error);
                  });
              }
              if (!userData.result) {
                this.show = false
                if (!response.data.result) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Authentication Failed",
                      icon: "XOctagonIcon",
                      variant: "danger",
                      text: `${response.data.message}`,
                    },
                  });
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.show = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Authentication Failed",
                  icon: "InfoIcon",
                  variant: "danger",
                  text: `Invalid Login Credentials!`,
                },
              });
            });
        }
        else {
          this.show = false
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
